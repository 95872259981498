<template>
  <div>
    <b-alert
      :variant="alertVariant"
      :show="dismissCountDown"
      dismissible
      @dismissed="dismissCountDown=0"
      @dismiss-count-down="countDownChanged"
    >
    <div class="alert-body">
        <span><strong>{{this.alertMessage}}</strong></span>
      </div>
    </b-alert>
    <b-form @submit.prevent>
      <b-row>
        <b-col xl="6">
          <div class="col-12 mb-3">
            <b-form-group
              label="Titulo da enquete"
              label-for="name"
            >
              <b-form-input
                id="name"
                :value="poll.question"
                v-model="poll.question"
              />
            </b-form-group>
          </div>
          <div class="col-12">
            <div v-for="(opt, idx) in poll.options" class="option md-12 mb-3 row" :key="idx">
                <b-form-group
                class="col-11"
                :label="'Questão ' + (idx+1)"
              >
                <b-form-input
                  id="options"
                  v-model="opt.name"
                />
              </b-form-group>
              <b-button
                  class="col-1"
                  style="height:40px; margin-top:27px"
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  type="submit"
                  variant="danger"
                  v-if="idx>0"
                  v-on:click="removeOption(idx)"
                >
                  -
              </b-button>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="submit"
                style="height:40px; margin-top:27px"

                variant="success"
                v-if="idx == 0"
                class="col-1"
                v-on:click="newOption()"
              >
                +
              </b-button>   
            </div>
            <div class="row mb-2">
              <b-form-group
                label="Número de opções marcadas"
                label-for="max_check"
                class="col-4"
              >
                <b-form-input
                  id="max_check"
                  :value="poll.max_check"
                  v-model="poll.max_check"
                  class="col-6  text-center"
                />
              </b-form-group>
              <b-form-group
                label="Opções de agendamento"
                label-for="scheduled"
                class="col-6"
              >
                <div class="d-flex mt-2">
                  <b-form-radio v-model="scheduled" selected="selected" class="col" name="scheduled" value="0">Início imediato</b-form-radio>
                  <b-form-radio v-model="scheduled" class="col" name="scheduled" value="1">Agendada</b-form-radio>
                </div>
              </b-form-group>
            </div>
            <div class="row" v-if="scheduled == true">
              <b-form-group
                label="Data de início"
                label-for="starts_at"
                class="col-3"
                >
                <b-form-datepicker 
                  name="starts_at" 
                  v-model="poll.starts_at" 
                  class="mb-2"
                  :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit'}"
                  :value-as-date="false"
                  :min="today"
                  placeholder="Selec."
                  >
                </b-form-datepicker>
              </b-form-group>
              <b-form-group
                label="Hora"
                label-for="start_date"
                class="col-3"
                >
                <b-form-timepicker 
                v-model="iniTime"
                placeholder="Selec."
                >
                </b-form-timepicker>
              </b-form-group>
              <b-form-group
                label="Data de finalização"
                label-for="starts_at"
                class="col-3"
                >
                <b-form-datepicker 
                  name="starts_at" 
                  v-model="poll.ends_at" 
                  class="mb-2"
                  :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit'}"
                  :value-as-date="false"
                  :min="today"
                  placeholder="Selec."
                  >
                </b-form-datepicker>
              </b-form-group>
              <b-form-group
                label="Hora de finalização"
                label-for="start_date"
                class="col-3"
                >
                <b-form-timepicker 
                v-model="endTime"
                placeholder="Selec."
                >
                </b-form-timepicker>
              </b-form-group>
            </div>
          </div>
        </b-col>
        <b-col lg="6">
          <h5 class="mt-2">{{this.poll.question}}</h5>
          <ul v-if="this.poll.options[0].name != ''">
            <li v-for="option in this.poll.options">{{option.name}}</li>
          </ul>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="12">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            type="submit"
            variant="primary"
            class="mr-1"
            v-on:click="createPoll()"
          >
            Cadastrar enquete
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </div>
  
</template>

<script>
import {
  BRow, BCol, BFormGroup, BFormInput, BFormRadio, BForm, BButton, BAlert,BFormDatepicker,BFormTimepicker
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive';

export default {
  components: {
    BAlert,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormRadio,
    BForm,
    BButton,
    BFormDatepicker,
    BFormTimepicker
  },
  directives: {
    Ripple,
  },
  data () {
    return {
      poll:{
        "question": "",
        "options" : [{"name": ""}],
        "max_check": "1",
        "starts_at": "",
        "ends_at": "",
      },
      dismissSecs: 2,
      dismissCountDown: 0,
      today:false,
      iniTime:false,
      endTime:false,
      scheduled:0,
      alertVariant: 'success',
      alertMessage: ''
    }
  },
  created() {
    console.log(this)
    const now = new Date()
    const todays = new Date(now.getFullYear(), now.getMonth(), now.getDate())
    const minDate = new Date(todays)
    var fiveMin = new Date(now.getTime() + 5*60000);
    this.iniTime = fiveMin.getHours() + ":" + fiveMin.getMinutes()
    var fiveMin = new Date(now.getTime() + 35*60000);
    this.endTime = fiveMin.getHours() + ":" + fiveMin.getMinutes()
    this.today = minDate
    this.poll.starts_at = minDate
  },
  methods: {
    newOption(){
      this.poll.options.push({ "name": "" });
    },
    removeOption(id){
      this.poll.options.splice(id, 1);
    },
    createPoll(){
      if(this.poll.options[0].name == '' || this.poll.question == ''){
        this.alertMessage = "Enquete deve ser preenchida minimamente!";
        this.alertVariant = "danger";
        this.showAlert()
        return;
      }
      if(this.scheduled == false){
        this.poll.starts_at = null;
        this.poll.ends_at = null;
      }else{
        this.poll.starts_at = this.poll.starts_at + " " + this.iniTime;
        this.poll.ends_at = this.poll.ends_at + " " + this.endTime;
      }
      this.$http
        .post('/polls/admin/new-poll',this.poll)
        .then(res => {
          console.log(res.data)
          this.alertMessage = res.data;
          this.alertVariant = "success";
          this.showAlert()
        })
    },
    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown
    },
    showAlert() {
      this.dismissCountDown = this.dismissSecs
    }
  },
}
</script>

<style>

</style>
